import { useEffect, useState } from "react";
import styled from "styled-components";
import print from "../../assets/images/print.svg";
import celebrate from "../../assets/images/celebrate.svg";
import glass from "../../assets/images/glass.svg";
import bag from "../../assets/images/bag.svg";
import books from "../../assets/images/books.svg";
import dots from "../../assets/images/dots.svg";
import line1 from "../../assets/images/arrow_left_line.svg";

const Categories = ({ handleChangeTab, categories }) => {
  const [selectedTypes, setSelectedTypes] = useState([]);

  const Container = styled.div`
    display: flex;
    gap: 20px;
    margin: 20px 0 50px;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;

    @media (min-width: 768px) {
      flex-direction: row;
      gap: 36px;
      margin: 37px 0 70px;
    }
  `;
  const Rectangle = styled.div`
    width: 260px;
    height: 110px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f8f8f8;
    border: 2px solid transparent;
    cursor: pointer;
    gap: 10px;
    font-size: 16px;

    &:hover {
      background-color: #e0e0e0;
    }

    @media (min-width: 768px) {
      width: 300px;
      height: 124px;
      font-size: 18px;
    }

    border-color: ${(props) => (props.isActive ? "#E5F97F" : "transparent")};
  `;
  const Button = styled.button`
    height: 52px;
    border-radius: 7px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #202020;
    color: #e5f97f;
    border: 0;
    margin: 33px 0;
    cursor: pointer;
  `;

  const handleClick = (type) => {
    setSelectedTypes((prevSelectedTypes) => {
      if (prevSelectedTypes.includes(type)) {
        return prevSelectedTypes.filter((item) => item !== type);
      } else {
        return [...prevSelectedTypes, type];
      }
    });
  };

  return (
    <>
      <Container>
        {categories?.map((c) => (
          <Rectangle
            key={c.id}
            isActive={selectedTypes.includes(c.id)}
            onClick={() => {
              handleClick(c.id);
            }}
          >
            <img src={c?.icon} width={24} height={24} />
            {c?.title}
          </Rectangle>
        ))}
      </Container>
      <Button onClick={() => handleChangeTab("finish", selectedTypes)}>
        إنشاء حساب
        <img src={line1} alt="" style={{ rotate: "-180deg" }} />
      </Button>
    </>
  );
};

export default Categories;
