import styled from "styled-components";
import cancel from "../../assets/images/cancel.svg";

const Header = () => {
  const Container = styled.div`
    padding: 25px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f5f5f5;
    text-align: center;
    height: 365px;

    @media (min-width: 768px) {
      padding: 40px 60px;
      flex-direction: row;
      justify-content: space-between;
      text-align: start;
      align-items: start;
    }

    @media (min-width: 1024px) {
      padding: 43px 100px;
    }

    @media (min-width: 1280px) {
      padding: 63px 134px;
    }
  `;

  const Button = styled.a`
    width: 120px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 7px;
    background: #e5f97f;
    font-size: 14px;
    font-weight: 700;
    color: #202020;
    transition: all 0.3s ease;
    cursor: pointer;
    border: none;
    margin-top: 20px;

    &:hover {
      background: #ecfaa4;
      transform: translateY(-2px);
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }

    @media (min-width: 768px) {
      width: 139px;
      height: 52px;
      font-size: 16px;
      margin-top: 0;
    }

    @media (min-width: 1024px) {
      width: 160px;
    }
  `;

  const Head = styled.h1`
    font-family: Tajawal !important;
    font-size: 24px;
    font-weight: 700;
    color: #0a1730;
    margin-bottom: 8px;
    line-height: 1.2;

    @media (min-width: 768px) {
      font-size: 32px;
    }

    @media (min-width: 1024px) {
      font-size: 40px;
    }
  `;

  const Desc = styled.span`
    font-size: 14px;
    font-weight: 400;
    color: #0a1730;
    opacity: 0.8;

    @media (min-width: 768px) {
      font-size: 15px;
    }

    @media (min-width: 1024px) {
      font-size: 16px;
      line-height: 1.5;
    }
  `;

  return (
    <Container>
      <div>
        <Head>التسجيل كمقدم خدمة</Head>
        <Desc>التسجيل كمقدم خدمة يمكنك من البحث عن فرص العمل وتقديم العروض</Desc>
      </div>
      <Button href="/agent-registration">
        <img src={cancel} alt="Cancel" />
        إلغاء
      </Button>
    </Container>
  );
};

export default Header;
