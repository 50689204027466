import AppleStoreIcon from "../../icons/AppleStoreIcon";
import GooglePlayIcon from "../../icons/GooglePlayIcon";
import { SCompDownloadApp } from "./styles/SCompDownloadApp";
import { SCompDownloadAppContainer } from "./styles/SCompDownloadAppContainer";
import { SCompDownloadAppContent } from "./styles/SCompDownloadAppContent";
import { SCompDownloadAppImg } from "./styles/SCompDownloadAppImg";
import { SCompDownloadAppImgCont } from "./styles/SCompDownloadAppImgCont";
import { SCompDownloadAppLinks } from "./styles/SCompDownloadAppLinks";
import { SCompDownloadAppList } from "./styles/SCompDownloadAppList";
import { SCompDownloadAppListItem } from "./styles/SCompDownloadAppListItem";
import { SCompDownloadAppText } from "./styles/SCompDownloadAppText";
import { SCompDownloadAppTitle } from "./styles/SCompDownloadAppTitle";
import { SCompDownloadAppWrapper } from "./styles/SCompDownloadAppWrapper";
import image from "../../assets/images/bottom.png";
import apple from "../../assets/images/apple.svg";
import google from "../../assets/images/google.svg";
import circle from "../../assets/images/tick-circle.svg";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import styled from "styled-components";

const CompDownloadApp = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  const ImageStyle = styled.div`
    display: block;
    width: 180px;
    object-fit: contain;
    position relative;
    left: 500px;
    bottom: 120px;
    @media (max-width: 1280px) {
      display: none;
    }
  `;
  const SCompDownloadAppTitle2 = styled.h2`
    font-weight: 700;
    font-size: 46px;
    line-height: 1.3;
    color: #202020;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      font-size: 36px;
      font-weight: 500;
    }
  `;
  return (
    <SCompDownloadAppContainer>
      <SCompDownloadAppWrapper data-aos="fade-left" style={{ alignItems: "start" }}>
        <SCompDownloadAppContent>
          <SCompDownloadAppTitle2>حقق دخل اضافي دون التقيد بوقت معين</SCompDownloadAppTitle2>
          <SCompDownloadAppText>
            تطبيقنا يتيح لك فرص عمل حرة تساهم في زيادة دخلك من خلال الوصول إلى مجموعة واسعة من العملاء، يمكنك اختيار
            المهام التي تناسب خبراتك ومهاراتك وتحديد أوقات العمل التي تناسبك وبدون عمولة للتطبيق.
          </SCompDownloadAppText>
          <SCompDownloadAppList>
            <SCompDownloadAppListItem>
              <img src={circle} alt="circle" style={{ marginLeft: "10px" }} />
              هوية وطنية سارية
            </SCompDownloadAppListItem>
            <SCompDownloadAppListItem>
              <img src={circle} alt="circle" style={{ marginLeft: "10px" }} />
              صورة شخصية واضحة الملامح
            </SCompDownloadAppListItem>
            <SCompDownloadAppListItem>
              <img src={circle} alt="circle" style={{ marginLeft: "10px" }} />
              التفعيل فوري للسعوديين
            </SCompDownloadAppListItem>
            <SCompDownloadAppListItem>
              <img src={circle} alt="circle" style={{ marginLeft: "10px" }} />
              للأجانب يستلزم التسجيل كشركة
            </SCompDownloadAppListItem>
            <SCompDownloadAppListItem>
              <img src={circle} alt="circle" style={{ marginLeft: "10px" }} />
              قد يتم طلب أي مستندات أخرى عند الحاجة
            </SCompDownloadAppListItem>
          </SCompDownloadAppList>
          <SCompDownloadAppLinks>
            <a target="_blank" href="https://apps.apple.com/sa/app/busy-agent/id6502257280">
              <img src={apple} alt="apple" data-aos="fade-right" />
            </a>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.busy.agent">
              <img src={google} alt="google" data-aos="fade-right" />
            </a>
          </SCompDownloadAppLinks>
        </SCompDownloadAppContent>
        <ImageStyle>
          <img src={image} alt="حمل التطبيق الأن !" data-aos="fade-right" />
        </ImageStyle>
      </SCompDownloadAppWrapper>
    </SCompDownloadAppContainer>
  );
};

export default CompDownloadApp;
