import React from "react";
import { Routes, Route } from "react-router-dom";

//components
import { GStyleBase } from "./constants/global-style";
import AboutUs from "./pages/about-us";
import BlogDetails from "./pages/blog-details";
import DownloadApp from "./pages/download-app";
import Blogs from "./pages/blogs";
import ContactUs from "./pages/contact-us";
import Home from "./pages/home";
import PageNotFound from "./pages/pageNotFound/PageNoFound";
import Footer from "./components/Footer";
import Header from "./components/Header";
import PrivacyPolicy from "./pages/faq";
import FaqDetails from "./pages/faq/FaqDetails";
import Privacy from "./pages/privacy-policy";
import AgentRegistration from "./pages/agent-registration";
import RegistrationForm from "./pages/registeration-from";

function App() {
  return (
    <main>
      <GStyleBase />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:id" element={<BlogDetails />} />
        <Route path="/faq" element={<PrivacyPolicy />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/faq/:FaqId" element={<FaqDetails />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/download-app" element={<DownloadApp />} />
        <Route path="/agent-registration" element={<AgentRegistration />} />
        <Route path="/registration/form" element={<RegistrationForm />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </main>
  );
}

export default App;
