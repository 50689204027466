import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "../../../constants/mediaquerys";

const Small = css`
  display: flex;
  margin-top: 32px;
  align-items: center;
  flex-direction: column;
  gap: 14px;
  a {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
`;

const Medium = css`
  flex-direction: row;
  a {
    width: auto;
  }
`;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SHeroButtons = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
