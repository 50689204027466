import React from "react";
import CompFaqs from "../../components/CompFaqs";
import CompSteps from "../../components/CompSteps";
import Hero from "../../components/Hero";
import { SHome } from "./styles/SHome";
import About from "../../components/About";
import HomeServices from "../../components/Services";
import { SDownloadApp } from "../download-app/styles/SDownloadApp";
import CompDownloadApp from "../../components/CompDownloadApp/Home";
import FindJob from "../../components/FindJob";

const Home = () => {
  return (
    <SHome>
      <Hero />
      <About />
      {/*   <AdvantagesComponent {...homeAdvantagesComponent} />
      <CompSteps />
      <CompFaqs />*/}
      <HomeServices />
      <SDownloadApp id="find-job">
        <FindJob />
      </SDownloadApp>
      <SDownloadApp id="find-service">
        <CompDownloadApp />
      </SDownloadApp>
    </SHome>
  );
};

export default Home;
