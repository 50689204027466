import styled from "styled-components";
import Header from "../../components/RegistrationForm/Header";
import Control from "./Control";
import { useEffect, useState } from "react";
import Type from "../../components/RegistrationForm/Type";
import MemberFrom from "../../components/RegistrationForm/MemberFrom";
import Categories from "../../components/RegistrationForm/Categories";
import axios, { all } from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const RegistrationForm = () => {
  const Container = styled.section`
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.05) -4px 9px 25px -6px;
    background: white;
    min-height: 420px;
    position: relative;
    margin: 0 20px;
    top: -170px;

    @media (min-width: 768px) {
      top: -160px;
      margin: 0 60px;
    }

    @media (min-width: 1024px) {
      margin: 0 100px;
    }

    @media (min-width: 1280px) {
      margin: 0 134px;
    }
  `;

  let [tabNumber, setTabNumber] = useState(0);
  let [type, setType] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [allData, setAllData] = useState({
    user_type: 1,
    national_id: 194,
    language: "ar",
    country_id: 1,
  });
  console.log(allData);
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get("https://busy.detailslocal.com/api/master_data").then((res) => {
      setCategories(res.data.data.categories);
      setCities(res.data.data.cities);
    });
  }, []);

  const handleChangeTab = (action, categories) => {
    if (action == "next" && tabNumber < 2) {
      setTabNumber(++tabNumber);
    } else if (action == "back" && tabNumber > 0) {
      setTabNumber(--tabNumber);
    } else if (action == "finish") {
      const updatedData = { ...allData, categories };

      const formData = new FormData();

      Object.keys(updatedData).forEach((key) => {
        const value = updatedData[key];

        if (Array.isArray(value)) {
          value.forEach((item) => {
            return formData.append(`${key}[]`, item);
          });
        } else {
          formData.append(key, value);
        }
      });

      axios.post("https://busy.detailslocal.com/api/user", formData).then((res) => {
        if (res.data.errors) {
          Object.keys(res.data.errors).map((er) => toast.error(res.data.errors[er][0]));
        } else {
          toast.success("تم التسجيل بنجاح!");
          navigate("/");
        }
      });
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  function activeFn() {
    switch (tabNumber) {
      case 0:
        return (
          <Type
            type={type}
            setType={setType}
            handleChangeTab={handleChangeTab}
            setAllData={setAllData}
            allData={allData}
          />
        );
      case 1:
        return (
          <MemberFrom
            handleChangeTab={handleChangeTab}
            setAllData={setAllData}
            allData={allData}
            cities={cities}
            profileImage={profileImage}
            setProfileImage={setProfileImage}
          />
        );
      case 2:
        return <Categories handleChangeTab={handleChangeTab} categories={categories} />;
    }
  }

  function activeTitle() {
    switch (tabNumber) {
      case 0:
        return "نوع المستخدم";
      case 1:
        return type == 1 ? "ارفع صورتك الشخصية" : "ارفع شعار الشركة";
      case 2:
        return "اختر الفئات المناسبة لتخصصك ";
    }
  }
  return (
    <div style={{ background: "#f5f5f5" }}>
      <Toaster position="top-center" reverseOrder={false} />
      <Header />
      <Container>
        <Control tabNumber={tabNumber} children={activeFn()} title={activeTitle()} handleChangeTab={handleChangeTab} />
      </Container>
    </div>
  );
};

export default RegistrationForm;
