import line2 from "../../assets/images/arrow_left_line_black.svg";
import styled from "styled-components";

const Control = ({ tabNumber, children, title, handleChangeTab }) => {
  const Container = styled.div`
    padding: 0 52px 33px;
  `;
  const Row = styled.div`
    background: #e5f97f;
    height: 12px;
    border-radius: 20px 20px 0px 0px;
  `;
  const Button = styled.button`
    height: 52px;
    border-radius: 7px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #202020;
    color: #e5f97f;
    border: 0;
    cursor: pointer;
  `;
  const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 44px;
  `;
  const Title = styled.h5`
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    color: #161313;
  `;
  const Span = styled.span`
    font-family: Tajawal !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    color: #161313;
  `;

  return (
    <>
      <Row style={{ width: tabNumber == 0 ? "33%" : tabNumber == 1 ? "66%" : "100%" }}></Row>
      <Container>
        <Header>
          <Title>{title}</Title>
          <Span>3\{tabNumber + 1}</Span>
        </Header>
        {children}

        <Button style={{ background: "#DEDEDE", color: "#202020" }} onClick={() => handleChangeTab("back")}>
          <img src={line2} alt="" style={{ rotate: "-180deg" }} />
          العودة
        </Button>
      </Container>
    </>
  );
};

export default Control;
