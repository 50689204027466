import React from "react";

import { SHeaderLink } from "./styles/SHeaderLink";
import { Link } from "react-router-dom";
import Button from "../Button";
import arrow from "../../assets/images/arrow-right.svg";
import "./styles/mobileNav.css";

const MobileNav = ({ toggleNav, setToggleNav }) => {
  return (
    <nav className={`mobile-nav ${toggleNav ? "none" : "block"}`}>
      <ul>
        {/*  <Link
          to="/about-us"
          className={({ isActive }) => (isActive ? "active" : "")}
          onClick={() => setToggleNav(true)}
        >
          <SHeaderLink>من نحن</SHeaderLink>
        </Link>

        <Link to="/blogs" className={({ isActive }) => (isActive ? "active" : "")} onClick={() => setToggleNav(true)}>
          <SHeaderLink>مقالات</SHeaderLink>
        </Link> */}

        <Link to="/faq" className={({ isActive }) => (isActive ? "active" : "")} onClick={() => setToggleNav(true)}>
          <SHeaderLink>أسئلة شائعة</SHeaderLink>
        </Link>
        <Link
          to="/contact-us"
          className={({ isActive }) => (isActive ? "active" : "")}
          onClick={() => setToggleNav(true)}
        >
          <SHeaderLink>أتصل بنا</SHeaderLink>
        </Link>
        {/*<Link to="/download-app" style={{ marginTop: "30px" }} onClick={() => setToggleNav(true)}>
          <Button btnType="with-icon" as="span">
            حمل التطبيق الأن
            <img src={arrow} style={{ marginRight: "16px" }} alt="left arrow" />
          </Button>
        </Link>*/}
      </ul>
    </nav>
  );
};

export default MobileNav;
