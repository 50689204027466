import { Link, NavLink } from "react-router-dom";
import Button from "../Button";
import { SHeader } from "./styles/SHeader";
import { SHeaderBtns } from "./styles/SHeaderBtns";
import { SHeaderContainer } from "./styles/SHeaderContainer";
import { SHeaderLink } from "./styles/SHeaderLink";
import { SHeaderLinks } from "./styles/SHeaderLinks";
import { SHeaderLinksAndBtns } from "./styles/SHeaderLinksAndBtns";
import { SHeaderLogo } from "./styles/SHeaderLogo";
import { SHeaderWrapper } from "./styles/SHeaderWrapper";
import arrow from "../../assets/images/arrow-right.svg";
import MobileNav from "./MobileNav";
import "./styles/mobileNav.css";
import { useState } from "react";

const Header = () => {
  const [toggleNav, setToggleNav] = useState(true);
  return (
    <SHeader className="header">
      <SHeaderContainer style={{ position: toggleNav ? "relative" : "fixed" }}>
        <SHeaderWrapper>
          <Link to="/" onClick={() => setToggleNav(true)}>
            <SHeaderLogo>
              <svg xmlns="http://www.w3.org/2000/svg" width="88" height="22" viewBox="0 0 88 22" fill="none">
                <path
                  d="M13.8731 8.86022H3.72668V4.49715H13.8731C15.5206 4.49715 16.2025 4.49714 16.2025 6.6928C16.2025 8.86022 15.5206 8.86022 13.8731 8.86022ZM3.72668 12.684H14.369C15.9533 12.684 16.6764 12.684 16.6764 14.964C16.6764 17.2438 15.9533 17.2438 14.369 17.2438H3.72668V12.684ZM18.5669 10.4894C19.5652 9.34413 20.1097 7.76671 20.1097 5.98969C20.1097 2.54048 18.2063 0.562231 14.8876 0.562231H0V21.1794H15C18.6149 21.1794 20.6057 19.0518 20.6057 15.1888C20.6057 12.7207 19.5748 11.2943 18.5669 10.4894Z"
                  fill="#0A1730"
                />
                <path
                  d="M39.3103 11.0821C39.3103 14.3518 38.5219 17.6944 32.6744 17.6944C26.8618 17.6944 26.0605 14.5167 26.0605 11.0821V0.562923H22.334V11.1384C22.334 18.3734 25.6196 21.7432 32.6744 21.7432C39.7286 21.7432 43.0141 18.3734 43.0141 11.1384V0.562923H39.3103V11.0821Z"
                  fill="#0A1730"
                />
                <path
                  d="M55.1871 8.7505C49.6671 8.27757 49.1463 7.70303 49.1463 6.30004C49.1463 5.05404 49.5572 3.93517 54.5194 3.93517C59.8773 3.93517 60.9753 5.19556 60.9753 7.08785V7.50828H64.9068L64.9047 7.08551C64.8822 2.51671 61.3301 7.62939e-05 54.9028 7.62939e-05C50.7446 7.62939e-05 45.2162 0.635754 45.2162 6.13136C45.2162 11.0456 48.8064 12.2653 55.0062 12.7115C61.1194 13.1758 61.6518 13.5396 61.6518 15.1896C61.6518 16.4083 60.9769 17.807 55.7146 17.807C49.3901 17.807 48.5372 16.0577 48.5372 14.3457V13.9249H44.5669L44.5856 14.3634C44.7206 17.5315 46.0237 21.7432 55.7367 21.7432C59.9629 21.7432 65.5812 21.0433 65.5812 14.9926C65.5812 9.65322 61.2217 9.27415 55.1871 8.7505Z"
                  fill="#0A1730"
                />
                <path d="M82.7425 0.511314L69.6797 21.1343H74.4657L87.5278 0.511314H82.7425Z" fill="#0A1730" />
                <path d="M75.9944 11.1631L69.6479 0.564607H64.877L71.224 11.1631H75.9944Z" fill="#0A1730" />
              </svg>
            </SHeaderLogo>
          </Link>
          <SHeaderLinksAndBtns>
            <SHeaderLinks>
              {/*  <NavLink to="/about-us" className={({ isActive }) => (isActive ? "active" : "")}>
                <SHeaderLink>من نحن</SHeaderLink>
              </NavLink>
              <NavLink to="/blogs" className={({ isActive }) => (isActive ? "active" : "")}>
                <SHeaderLink>مقالات</SHeaderLink>
              </NavLink>*/}
              <NavLink to="/faq" className={({ isActive }) => (isActive ? "active" : "")}>
                <SHeaderLink>أسئلة شائعة</SHeaderLink>
              </NavLink>
              <NavLink to="/contact-us" className={({ isActive }) => (isActive ? "active" : "")}>
                <SHeaderLink>أتصل بنا</SHeaderLink>
              </NavLink>
            </SHeaderLinks>
            <SHeaderBtns>
              {/* <Link to="/download-app">
                <Button btnType="with-icon" as="span">
                  حمل التطبيق الأن
                  <img src={arrow} style={{ marginRight: "16px" }} alt="left arrow" />
                </Button>
              </Link>*/}
            </SHeaderBtns>
          </SHeaderLinksAndBtns>
          {toggleNav ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="30"
              height="30"
              viewBox="0 0 50 50"
              className="bars"
              onClick={() => setToggleNav(false)}
            >
              <path d="M 5 8 A 2.0002 2.0002 0 1 0 5 12 L 45 12 A 2.0002 2.0002 0 1 0 45 8 L 5 8 z M 5 23 A 2.0002 2.0002 0 1 0 5 27 L 45 27 A 2.0002 2.0002 0 1 0 45 23 L 5 23 z M 5 38 A 2.0002 2.0002 0 1 0 5 42 L 45 42 A 2.0002 2.0002 0 1 0 45 38 L 5 38 z"></path>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              className="bars"
              onClick={() => setToggleNav(true)}
            >
              <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"></path>
            </svg>
          )}
        </SHeaderWrapper>
      </SHeaderContainer>
      <MobileNav toggleNav={toggleNav} setToggleNav={setToggleNav} />
    </SHeader>
  );
};
export default Header;
