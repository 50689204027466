import styled from "styled-components";
import { useForm, useWatch } from "react-hook-form";
import active from "../../assets/images/active-radio.svg";
import circle from "../../assets/images/camera.svg";
import notActive from "../../assets/images/not-active-radio.svg";
import { useEffect, useRef, useState } from "react";
import line1 from "../../assets/images/arrow_left_line.svg";

const FormWrapper = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 37px;
  padding: 0px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    gap: 33px 29px;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 16px;
`;

const Input = styled.input`
  height: 50px;
  border-radius: 5px;
  border: 1px solid #dadada;
  padding: 0 14px;
  width: 100%;
  &:focus {
    outline: none;
    border-color: #6c63ff;
  }

  @media (min-width: 768px) {
    height: 60px;
  }
`;
const Select = styled.select`
  height: 50px;
  border-radius: 5px;
  border: 1px solid #dadada;
  width: 100%;
  display: flex;
  padding: 12.5px 14px;

  &:focus {
    outline: none;
    border-color: #6c63ff;
  }

  @media (min-width: 768px) {
    padding: 18px 14px;
    height: 60px;
  }
`;
const File = styled.input`
  height: 50px;
  border-radius: 5px;
  border: 1px solid #dadada;
  width: 100%;
  display: flex;
  padding: 12.5px 14px;

  &:focus {
    outline: none;
    border-color: #6c63ff;
  }

  @media (min-width: 768px) {
    padding: 18px 14px;
    height: 60px;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 20px;
  background: #f8f8f8;
  height: 50px;
  padding: 0 15px;
  border-radius: 5px;

  @media (min-width: 768px) {
    height: 60px;
    gap: 43px;
    padding-right: 38.3px;
  }
`;

const SingleColumn = styled.div`
  grid-column: span 1;
  @media (max-width: 1024px) {
    grid-column: span 2;
  }
`;

const DoubleColumn = styled.div`
  grid-column: span 2;
`;

const ErrorText = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 4px;
`;

const RadioText = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;

  @media (min-width: 768px) {
    font-size: 15px;
  }
`;

const CustomRadio = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;

  @media (min-width: 768px) {
    gap: 13px;
    font-size: 14px;
  }
`;
const ProfileCircle = styled.div`
  width: 154px;
  height: 154px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f8f8;
  cursor: pointer;
  > img {
    width: 24px;
    height: 24px;
    opacity: 0.6;
  }
  & .profile-pic {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 1;
    object-fit: cover;
  }
`;
const HiddenInput = styled.input`
  display: none;
`;
const SubLabel = styled.span`
  font-size: 13px;
  font-weight: 300;
  line-height: 15.6px;
  opacity: 0.5;
`;

const Button = styled.button`
  height: 52px;
  border-radius: 7px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #202020;
  color: #e5f97f;
  border: 0;
  margin: 33px 0;
  cursor: pointer;
`;

const MemberForm = ({ handleChangeTab, setAllData, allData, cities, profileImage, setProfileImage }) => {
  const [selectedGender, setSelectedGender] = useState("");
  const [hasLisence, setHasLisence] = useState("");
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);

  const handleProfileClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setProfileImage(imageURL);
      setFile(file);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  useEffect(() => {
    setValue("first", allData.first || "");
    setValue("last", allData.last || "");
    setValue("company_name", allData.company_name || "");
    setValue("freelance_doc_number", allData.freelance_doc_number || "");
    setValue("iban_number", allData.iban_number || "");
    if (allData.type == "person") {
      setValue("gover_id_num", allData.gover_id_num || "");
    }
    setValue("company_reg_number", allData.company_reg_number || "");
    setValue("email", allData.email || "");
    setValue("birthday", allData.birthday?.replaceAll("/", "-") || "");
    setValue("city_id", allData.city_id || "");
    setValue("mobile", "966" + allData.mobile || "");
    setValue("password", allData.password || "");
    setValue("confirmPassword", allData.confirmPassword || "");
    setValue("gover_expire_date", allData.gover_expire_date || "");
    setSelectedGender(allData?.sex);
  }, [allData, setValue]);

  const onSubmit = (data) => {
    const values = {
      ...data,
      image: file,
      iban_photo: data?.iban_photo[0],
      sex: selectedGender,
      birthday: data.birthday.replaceAll("-", "/"),
      mobile: data.mobile.replace("966", "").trim(),
    };
    setAllData({ ...allData, ...values });
    handleChangeTab("next");
  };

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
      <SingleColumn>
        <HiddenInput
          type="file"
          id="image"
          name="image"
          {...register("image")}
          ref={fileInputRef}
          onChange={handleImageChange}
        />
        <ProfileCircle onClick={handleProfileClick}>
          {profileImage ? (
            <img className="profile-pic" src={profileImage} alt="Profile Preview" />
          ) : (
            <img src={circle} alt="Default Profile" />
          )}
        </ProfileCircle>
        {errors.image && <ErrorText>{errors.image.message}</ErrorText>}
      </SingleColumn>
      <SingleColumn></SingleColumn>
      <SingleColumn>
        <Label htmlFor="first">الأسم الأول</Label>
        <Input type="text" id="first" {...register("first", { required: "مطلوب" })} />
        {errors.first && <ErrorText>{errors.first.message}</ErrorText>}
      </SingleColumn>

      <SingleColumn>
        <Label htmlFor="last">الأسم الأخير</Label>
        <Input type="text" id="last" {...register("last", { required: "مطلوب" })} />
        {errors.last && <ErrorText>{errors.last.message}</ErrorText>}
      </SingleColumn>

      <SingleColumn>
        <Label htmlFor="mobile">رقم الجوال</Label>
        <Input
          type="number"
          id="mobile"
          {...register("mobile", {
            required: "مطلوب",
            pattern: {
              value: /^\966\d{10}$/,
              message: "رقم الجوال يجب أن يبدأ بـ 966 ويتبعه 10 أرقام",
            },
          })}
        />
        {errors.mobile && <ErrorText>{errors.mobile.message}</ErrorText>}
      </SingleColumn>

      <SingleColumn>
        <Label htmlFor="email">البريد الإلكتروني</Label>
        <Input
          type="email"
          id="email"
          {...register("email", {
            required: "مطلوب",
            pattern: {
              value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
              message: "البريد الإلكتروني غير صالح",
            },
          })}
        />
        {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
      </SingleColumn>

      <SingleColumn>
        <Label htmlFor="birthday">تاريخ الميلاد</Label>
        <Input type="date" id="birthday" {...register("birthday", { required: "مطلوب" })} />
        {errors.birthday && <ErrorText>{errors.birthday.message}</ErrorText>}
      </SingleColumn>

      <SingleColumn>
        <Label htmlFor="city_id">المدينة</Label>
        <Select id="city_id" {...register("city_id", { required: "مطلوب" })}>
          <option value="" disabled hidden>
            اختر المدينة
          </option>
          {cities?.map((c) => (
            <option value={c?.id} key={c?.id}>
              {c?.city_name}
            </option>
          ))}
        </Select>
        {errors.city_id && <ErrorText>{errors.city_id.message}</ErrorText>}
      </SingleColumn>

      <SingleColumn>
        <Label htmlFor="password">كلمة المرور</Label>
        <Input
          type="password"
          id="password"
          {...register("password", { required: "مطلوب", minLength: { value: 6, message: "كلمة المرور قصيرة" } })}
        />
        {errors.password && <ErrorText>{errors.password.message}</ErrorText>}
      </SingleColumn>

      <SingleColumn>
        <Label htmlFor="confirmPassword">تأكيد كلمة المرور</Label>
        <Input
          type="password"
          id="confirmPassword"
          {...register("confirmPassword", {
            required: "مطلوب",
            validate: (value) => value === getValues("password") || "كلمة المرور غير متطابقة",
          })}
        />
        {errors.confirmPassword && <ErrorText>{errors.confirmPassword.message}</ErrorText>}
      </SingleColumn>

      <SingleColumn>
        <Label>الجنس</Label>
        <RadioGroup>
          <CustomRadio onClick={() => setSelectedGender("male")}>
            {selectedGender === "male" ? <img src={active} alt="" /> : <img src={notActive} alt="" />}
            <RadioText>ذكر</RadioText>
          </CustomRadio>
          <CustomRadio onClick={() => setSelectedGender("female")}>
            {selectedGender === "female" ? <img src={active} alt="" /> : <img src={notActive} alt="" />}
            <RadioText>أنثى</RadioText>
          </CustomRadio>
        </RadioGroup>
      </SingleColumn>

      <SingleColumn>
        <Label htmlFor="gover_expire_date">تاريخ الإنتهاء</Label>
        <Input type="date" id="gover_expire_date" {...register("gover_expire_date", { required: "مطلوب" })} />
        {errors.gover_expire_date && <ErrorText>{errors.gover_expire_date.message}</ErrorText>}
      </SingleColumn>

      <SingleColumn>
        <Label htmlFor="company_name">اسم الشركة او المؤسسة</Label>
        <Input type="text" id="company_name" {...register("company_name", { required: "مطلوب" })} />
        {errors.last && <ErrorText>{errors.last.message}</ErrorText>}
      </SingleColumn>

      {allData.type === "person" ? (
        <SingleColumn>
          <Label htmlFor="gover_id_num">رقم الهوية الوطنية</Label>
          <Input
            type="number"
            id="gover_id_num"
            {...register("gover_id_num", {
              required: "مطلوب",
              pattern: {
                value: /^1\d{9}$/,
                message: "رقم الهوية يجب أن يبدأ بـ '1' ويليه 9 أرقام",
              },
            })}
          />
          {errors.gover_id_num && <ErrorText>{errors.gover_id_num.message}</ErrorText>}
        </SingleColumn>
      ) : (
        <SingleColumn>
          <Label htmlFor="company_reg_number">رقم السجل التجارى</Label>
          <Input
            type="number"
            id="company_reg_number"
            {...register("company_reg_number", {
              required: "مطلوب",
              pattern: {
                value: /^700\d{7}$/,
                message: "الرقم الموحد يجب أن يبدأ بـ '700' ويليه 7 أرقام",
              },
            })}
          />
          {errors.company_reg_number && <ErrorText>{errors.company_reg_number.message}</ErrorText>}
        </SingleColumn>
      )}

      <SingleColumn>
        <Label>هل تملك رخصة عمل حر ؟</Label>
        <RadioGroup>
          <CustomRadio onClick={() => setHasLisence("yes")}>
            {hasLisence === "yes" ? <img src={active} alt="" /> : <img src={notActive} alt="" />}
            <RadioText>نعم</RadioText>
          </CustomRadio>
          <CustomRadio onClick={() => setHasLisence("no")}>
            {hasLisence === "no" ? <img src={active} alt="" /> : <img src={notActive} alt="" />}
            <RadioText>لا</RadioText>
          </CustomRadio>
        </RadioGroup>
      </SingleColumn>
      {hasLisence === "no" && <SingleColumn></SingleColumn>}

      {hasLisence === "yes" ? (
        <SingleColumn>
          <Label htmlFor="licenseNumber">رقم الرخصة</Label>
          <Input
            type="text"
            id="freelance_doc_number"
            {...register("freelance_doc_number", {
              required: "مطلوب",
              validate: (value) => (!/^FL-\d{9}$/.test(value) ? "يجب أن يبدأ بـ -FL ثم 9 أرقام" : true),
            })}
          />
          {errors.freelance_doc_number && <ErrorText>{errors.freelance_doc_number.message}</ErrorText>}
        </SingleColumn>
      ) : (
        ""
      )}
      <SingleColumn>
        <Label htmlFor="iban_number">رقم الأيبان</Label>
        <Input
          type="text"
          id="iban_number"
          {...register("iban_number", {
            required: "مطلوب",
            pattern: {
              value: /^SA\d{24}$/,
              message: "رقم الأيبان يجب أن يبدأ بـ 'SA' ويليه 24 رقم",
            },
          })}
        />
        {errors.iban_number && <ErrorText>{errors.iban_number.message}</ErrorText>}
      </SingleColumn>

      <SingleColumn>
        <Label htmlFor="iban_photo">
          صورة شهادة الأيبان <SubLabel>(يجب ان يطابق اسمك في الهوية مع اسم صاحب الحساب)</SubLabel>
        </Label>
        <File type="file" id="iban_photo" name="iban_photo" {...register("iban_photo", { required: "مطلوب" })} />

        {errors.iban_photo && <ErrorText>{errors.iban_photo.message}</ErrorText>}
      </SingleColumn>

      <DoubleColumn>
        <Button type="submit">
          المتابعة
          <img src={line1} alt="" style={{ rotate: "-180deg" }} />
        </Button>
      </DoubleColumn>
    </FormWrapper>
  );
};

export default MemberForm;
