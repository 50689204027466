import AppleStoreIcon from "../../icons/AppleStoreIcon";
import GooglePlayIcon from "../../icons/GooglePlayIcon";
import { SCompDownloadApp } from "./styles/SCompDownloadApp";
import { SCompDownloadAppContainer } from "./styles/SCompDownloadAppContainer";
import { SCompDownloadAppContent } from "./styles/SCompDownloadAppContent";
import { SCompDownloadAppImg } from "./styles/SCompDownloadAppImg";
import { SCompDownloadAppImgCont } from "./styles/SCompDownloadAppImgCont";
import { SCompDownloadAppLinks } from "./styles/SCompDownloadAppLinks";
import { SCompDownloadAppList } from "./styles/SCompDownloadAppList";
import { SCompDownloadAppListItem } from "./styles/SCompDownloadAppListItem";
import { SCompDownloadAppText } from "./styles/SCompDownloadAppText";
import { SCompDownloadAppTitle } from "./styles/SCompDownloadAppTitle";
import { SCompDownloadAppWrapper } from "./styles/SCompDownloadAppWrapper";
import image from "../../assets/images/download-app.png";
import circle from "../../assets/images/tick-circle.svg";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const CompDownloadApp = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <SCompDownloadApp>
      <SCompDownloadAppContainer>
        <SCompDownloadAppWrapper data-aos="fade-left">
          <SCompDownloadAppContent>
            <SCompDownloadAppTitle>حمل التطبيق الأن !</SCompDownloadAppTitle>
            <SCompDownloadAppText>
              أول تطبيق سعودي يتبنى نموذج تقديم الخدمات عند الطلب بين أصحاب المهارات الراغبين بالعمل وطالبي الخدمات من
              أفراد أو شركات ، حيث يتم طلب الخدمة وتنفيذها من قبل مقدم الخدمة في وقت محدد وإنهاء العمل وتقييم مقدم
              الخدمة بعد نهاية العمل .
            </SCompDownloadAppText>
            <SCompDownloadAppList>
              <SCompDownloadAppListItem>
                <img src={circle} alt="circle" style={{ marginLeft: "10px" }} />
                توفير العمل لدي الأفراد و الشركات
              </SCompDownloadAppListItem>
              <SCompDownloadAppListItem>
                <img src={circle} alt="circle" style={{ marginLeft: "10px" }} />
                توفير الخدمات لمقدمين الخدمة
              </SCompDownloadAppListItem>
              <SCompDownloadAppListItem>
                <img src={circle} alt="circle" style={{ marginLeft: "10px" }} />
                إمكانية المحادثة بين مقدم الخدمة و الموظف
              </SCompDownloadAppListItem>
              <SCompDownloadAppListItem>
                <img src={circle} alt="circle" style={{ marginLeft: "10px" }} />
                سرعة الإستجابة و أفضل خدمة
              </SCompDownloadAppListItem>
              <SCompDownloadAppListItem>
                <img src={circle} alt="circle" style={{ marginLeft: "10px" }} />
                توفير الخدمات لمقدمين الخدمة
              </SCompDownloadAppListItem>
            </SCompDownloadAppList>
            <SCompDownloadAppLinks>
              <a target="_blank" href="https://apps.apple.com/sa/app/busy-agent/id6502257280">
                <AppleStoreIcon />
              </a>
              <a target="_blank" href="https://play.google.com/store/apps/details?id=com.busy.agent">
                <GooglePlayIcon />
              </a>
            </SCompDownloadAppLinks>
          </SCompDownloadAppContent>
          <SCompDownloadAppImgCont>
            <SCompDownloadAppImg>
              <img src={image} alt="حمل التطبيق الأن !" data-aos="fade-right" />
            </SCompDownloadAppImg>
          </SCompDownloadAppImgCont>
        </SCompDownloadAppWrapper>
      </SCompDownloadAppContainer>
    </SCompDownloadApp>
  );
};

export default CompDownloadApp;
