import React from "react";
import "./style/index.css";
import Button from "../Button";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <section className="about-sec">
      <h2>عن التطبيق</h2>
      <p>
      بيزي هو تطبيق سعودي يتيح للأفراد والشركات الوصول إلى شبكة واسعة من المهنيين المستقلين لتنفيذ مهام قصيرة الأجل أو مشاريع محددة. يُمكن للمستخدمين طلب خدمات متنوعة أو مهام اليومية، مع مرونة في تحديد الوقت والتكلفة. يوفر التطبيق منصة آمنة تضمن حقوق الطرفين من خلال عقود إلكترونية وآليات دفع موثوقة، مما يساهم في تعزيز الإنتاجية وتوفير فرص عمل إضافية للأفراد وتحقيق قيمة مضافة للشركات.
      </p>
      <Link to="/about-us">
        <Button btnType="primary" as="span">
          عرض المزيد
        </Button>
      </Link>
    </section>
  );
};

export default About;
