import React, { useEffect } from "react";
import styled from "styled-components";
import icon1 from "../../assets/images/Group1.svg";
import icon2 from "../../assets/images/Group2.svg";
import icon3 from "../../assets/images/Group3.svg";
import icon4 from "../../assets/images/Group4.svg";
import icon5 from "../../assets/images/Group5.svg";
import icon6 from "../../assets/images/Group6.svg";
import icon7 from "../../assets/images/Group7.svg";
import Aos from "aos";

const data = [
  {
    id: 1,
    title: "الصيانة المنزلية",
    desc: "تشمل جميع الأعمال المنزلية",
    icon: icon1,
  },
  {
    id: 2,
    title: "خدمات النظافة",
    desc: "تشمل جميع الأعمال المنزلية",
    icon: icon2,
  },
  {
    id: 3,
    title: "المطاعم و المقاهي",
    desc: "تشمل جميع الأعمال المنزلية",
    icon: icon3,
  },
  {
    id: 4,
    title: "الضيافة و الفعاليات",
    desc: "تشمل جميع الأعمال المنزلية",
    icon: icon4,
  },
  {
    id: 5,
    title: "التعليم الخاص",
    desc: "تشمل جميع الأعمال المنزلية",
    icon: icon5,
  },
  {
    id: 6,
    title: "رعاية الأطفال",
    desc: "تشمل جميع الأعمال المنزلية",
    icon: icon6,
  },
  {
    id: 7,
    title: "العناية و التجميل",
    desc: "تشمل جميع الأعمال المنزلية",
    icon: icon7,
  },
];

const Container = styled.div`
  background-color: #042131;
  padding: 96px 0 118px;
  margin-top: 92px;

  @media (max-width: 768px) {
    margin-top: 62px;
    padding: 80px 0 90px;
  }
`;

const Title = styled.h1`
  color: #ffffff;
  text-align: center;
  font-size: 32px;
  margin-bottom: 41px;

  @media (max-width: 768px) {
    font-size: 27px;
  }
`;

const ServicesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px 22px;

  @media (max-width: 768px) {
    gap: 16px 12px;
  }
`;

const ServiceCard = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  background-color: #f9f9f9;
  border-radius: 15px;
  padding: 16px;
  width: 408px;
  height: 123px;

  @media (max-width: 1364px) {
    width: 358px;
  }

  @media (max-width: 768px) {
    width: 90%;
    height: auto;
    padding: 16px 14px;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    height: auto;
    gap: 7px;
    width: 45%;
  }
`;

const Icon = styled.img`
  width: 48px;
  height: 48px;
`;

const ServiceTitle = styled.h3`
  color: #1b1b1b;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
  @media (max-width: 480px) {
    font-size: 15.5px;
    text-align: center;
    line-height: 22px;
  }
`;

const HomeServices = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);
  return (
    <Container>
      <Title>خدمات التطبيق</Title>
      <ServicesWrapper>
        {data.map((d) => (
          <ServiceCard key={d.id} data-aos="fade-up">
            <Icon src={d.icon} alt="icon" />
            <ServiceTitle>{d.title}</ServiceTitle>
          </ServiceCard>
        ))}
      </ServicesWrapper>
    </Container>
  );
};

export default HomeServices;
