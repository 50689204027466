import "./privacy.css";

const Privacy = () => {
  return (
    <section className="privay-par">
      تاريخ أخر إصدار (18 يناير 2023)
      <br />
      <h4>مقدمة</h4>
      • شكرًا لثقتكم في تطبيق Busy التابع لمؤسسة تفاصيل الأفكار لتقنية المعلومات، سجل رقم: 7002592215 بالمملكة العربية
      السعودية.
      <br />
      • يتطلب تقديم خدمات التطبيق لأصحاب المهارات وأصحاب الطلبات المسجلين لدينا بالتطبيق جمع ومعالجة بعض البيانات عنهم
      للأغراض الموضحة في هذه السياسة.
      <br />
      • يسعى تطبيق Busy والمؤسسة المالكة له (مؤسسة تفاصيل الأفكار لتقنية المعلومات) ومقرها المملكة العربية السعودية إلى
      الحفاظ على خصوصية بياناتك الشخصية ونمتثل في ذلك لأحكام نظام التجارة الالكترونية السعودي ولائحته التنفيذية بشأن
      حماية البيانات الشخصية، وقد قمنا بصياغة هذه السياسة "سياسة الخصوصية" لنوضح لك ماهية البيانات التي يتم جمعها عنك،
      والأغراض التي من أجلها يتم استخدام هذه البيانات.
      <br />
      • يشير مصطلح البيانات إلى كافة البيانات المتعلقة بمستخدمي تطبيق Busy والتي تشمل الاسم والعنوان وبيانات الاتصال
      خاصة بهم وأي بيانات أخرى من شأنها التعريف بهوية المستخدم كذلك البيانات الأخرى المتعلقة بأنشطة المستخدم من خلال
      التطبيق والبيانات المرتبطة بالأجهزة المستخدمة في عملية الدخول إلى التطبيق.
      <br />
      • تسري هذه السياسة "سياسة الخصوصية" على تطبيق Busy وكافة الخدمات المقدمة من خلاله وكافة الخدمات أو التطبيقات
      الأخرى التابعة لنا والتي تشاركنا تقديم الخدمات.
      <br />
      <br />
      • يرجى قراءة هذه السياسة بالعناية اللازمة:
      <br />
      <br />
      <h5>(1) بيانات تسجيل الحساب</h5>
      • نجمع البيانات بيانات تسجيل الحساب على النحو الآتي:
      <br />
      - بيانات تسجيل حساب صاحب الطلب وتشمل (الاسم بالكامل، البريد الإلكتروني، الجنس، رقم الجوال، الجنسية، رقم الهوية،
      العنوان).
      <br />
      - بيانات تسجيل حساب صاحب المهارة وتشمل (الاسم بالكامل، البريد الإلكتروني، الجنس، تاريخ الميلاد، رقم الجوال،
      الجنسية، رقم الهوية، العنوان، بيانات الحساب البنكي، المؤهل العلمي، الوظيفة الحالية، المهارات التي يستطيع القيام
      بها).
      <br />
      - نجمع أي بيانات أخرى نطلبها من أي من المستخدمين في أي وقت من الأوقات من خلال التطبيق أو بأي وسيلة إلكترونية أخرى
      سواء لتحديث بيانات الحساب أو لإضافة بيانات جديدة بحساب المستخدم بالتطبيق.
      <br />
      • تزودنا ببيانات حسابك عبر تطبيق Busy على مسئوليتك الشخصية، وفي حال تزويدنا ببيانات خاطئة أو غير محدثة أو خاصة
      بالغير سنقوم بحذفها على الفور.
      <br />
      • يحظر تقديم بيانات كاذبة أو بيانات خاصة بأشخاص آخرين عبر تطبيق Busy، وأنت تعلم وتوافق على أن من حق التطبيق تقديم
      كافة بياناتك للجهات المختصة في حال طلب ذلك أو إذا كان ما قدمته من بيانات من خلال التطبيق سبب مشكلات قانونية.
      <br />
      • بيانات الحساب الخاصة بك في تطبيق Busy مثل اسم الحساب والصورة الخاصة بالحساب التي قمت بإضافتها ستكون مرئية لجميع
      مستخدمي التطبيق.
      <br />
      <br />
      <h5>(2) طلبات الخدمة</h5>
      • نحتفظ ببيانات الخدمة التي يقوم صاحب الطلب بنشرها من خلال التطبيق والتي يتمكن أصحاب المهارات التي من تقديم عروض
      الأسعار من خلال هذه الخدمة من خلال التطبيق.
      <br />
      • عند فتح طلب الخدمة من خلال التطبيق سوف يتم إثبات كافة الاتفاقات التي تمت بين صاحب الطلب وصاحب المهارة وسوف يثبت
      بالطلب رقم الطلب وتاريخ الطلب وموعد التسليم وأسماء الأطراف كما هي مسجلة بالتطبيق.
      <br />
      • يتم جمع البيانات والمعلومات والمراسلات التي يتم تبادلها بين أصحاب الطلبات وأصحاب المهارات من خلال التطبيق، ويتم
      الإبقاء عليها داخل التطبيق للحفاظ على كافة الحقوق الخاصة بالمعاملات ولأغراض المراجعات المحاسبية والقانونية في أي
      وقت.
      <br />
      • إذا كانت الخدمات ستقدم بشكل مادي في موقع صاحب الطلب أو أي عنوان يحدده صاحب الطلب فسوف يتم إثبات العنوان بالتطبيق
      ضمن طلب الخدمة كما سيتم إثبات أي وسائل اتصال سيتم تقديمها في طلب الخدمة.
      <br />
      • يجب أن يقوم صاحب الطلب بتزويدنا ببريد الكتروني صالح للاستخدام، ويستخدم من جانب التطبيق لأغراض إرسال الفاتورة
      وطلب الاستبيانات والتقييمات.
      <br />
      • نقوم بجمع ومعالجة كافة البيانات التي يزودنا بها صاحب الطلب أثناء تقديم طلبات الإلغاء للخدمات المقدمة من خلال
      التطبيق طبقًا للشروط والأحكام بما في ذلك الأسباب والمشكلات التي تواجه الأطراف.
      <br />
      <br />
      <h5>(3) بيانات الاتصال</h5>
      • تزودنا بأرقام وبيانات الاتصال بك أثناء عملية التسجيل بتطبيق Busy، وهذه البيانات تكون متاحة فقط لإدارة التطبيق
      ولن نقوم بإضافتها في الصفحة الشخصية الخاصة بك إلا إذا تطلبت سياسات تقديم الخدمات ذلك.
      <br />
      • نجمع البيانات التي تقدمها لنا أثناء التواصل معنا للاستفسار عن أي من خدماتنا أو مواجهة المشكلات التي تواجه
      استخدامك لتطبيق Busy.
      <br />
      • نجمع البيانات التي تقدمها لنا للإبلاغ عن المحتوى أو الأخطاء أو الخدمات التي تخالف الشروط والسياسات الخاصة بتطبيق
      Busy.
      <br />
      • نجمع محتوى الرسائل التي تقوم بإرسالها لنا عبر تطبيق Busy، وسيكون من حق التطبيق الاحتفاظ بها لاعتبارات الأمان
      وضمان سير العمل بالتطبيق.
      <br />
      <br />
      <h5>(4) بيانات الدفع</h5>
      • تقدم بعض خدمات الدفع الإلكتروني لصاحب الطلب من خلال التطبيق بواسطة مزود خدمة دفع إلكتروني متعاقد معنا، لتزويدنا
      ببوابة الدفع الالكتروني الآمنة التي يقوم المستخدم بالدفع من خلالها، والتطبيق لا يحتفظ ببيانات الدفع الخاصة بصاحب
      الطلب في هذه الحالة بل سيقوم صاحب الطلب بالاتصال ببوابة الدفع الالكترونية بشكل مباشر عند الدفع وستقوم البوابة بشكل
      آلي بتشفير بيانات صاحب الطلب للحفاظ على أمانها.
      <br />
      • بعض وسائل الدفع التي يقوم صاحب الطلب باختيارها تفرض علينا معالجة عملية الدفع بشكل مادي مثل خدمات التحويل البنكي،
      فيقوم التطبيق بالتحقق من عملية الدفع ويحق لنا قيد هذه المعاملة ضمن سجلاتنا الالكترونية.
      <br />
      • يزودنا صاحب المهارة برقم حسابه البنكي والاسم لأغراض استلام الأرباح الخاصة به وفقًا للشروط والأحكام، وسوف نقوم
      بمعالجة كافة التحويلات التي نقوم بها لصالح صاحب المهارة للتأكد من استلام الأرباح أو لمعالجة أي مشكلة تواجه عملية
      التحويل.
      <br />
      • قد يطلب منا مزود خدمة الدفع الالكتروني أو البنوك المستخدمة في عمليات التحويل بعض البيانات للتحقق من قانونية
      عمليات الدفع وأنها مقابل خدمات مشروعة.
      <br />
      <br />
      <h5>(5) تقييم الخدمات</h5>
      يتيح تطبيق Busy تقييم الخدمات من خلال التطبيق، فيكون لصاحب الطلب تقييم صاحب المهارة ويكون لصاحب المهارة تقييم صاحب
      الطلب، ويكون متاح إضافة التعليقات من خلال التقييم، ويعلم المستخدم أن محتوى التقييم سيكون متاح من خلال التطبيق
      لجميع المستخدمين والزوار، وننصح المستخدمين بعدم نشر أي بيانات تعريفية خاصة بهم من خلال التقييم، فقد لا يتمكن
      المستخدم من تعديل تقييمه مرة أخرى.
      <br />
      <br />
      <h5>(6) البيانات التي يتم جمعها تلقائيًا</h5>
      • يجمع تطبيق Busy بعض البيانات الخاصة بالمستخدمين، والتي لا تحدد هوية المستخدم أو بيانات الاتصال به، ويتم جمع هذه
      البيانات بشكل تلقائي والتي تشمل على سبيل المثال (بيانات الأجهزة المستخدمة في عمليات الاتصال، ووقت الاتصال، ومدته،
      ومكان الاتصال، وعنوان IP، الصفحات التي يتم زيارتها، فئات الإعلانات التي يستهدفها المستخدم).
      <br />
      • في تطبيق Busy نجمع بعض البيانات المتعلقة باستعمال الخدمات أو ذات الصلة بها، والبيانات المتعلقة بتشخيص الأعطال
      وقياس أداء الخدمات المتاحة من خلال التطبيق، ويتضمن ذلك بيانات حول نشاطك كمستخدم عبر التطبيق مثل طريقة استعمالك
      لخدماتنا وكيفية تفاعلك مع الآخرين عبر خدماتنا.
      <br />
      <br />
      <h5>(7) ملفات تعريف الارتباط (سياسة الكوكيز)</h5>
      • يعتمد تطبيق Busy تقنية ملفات تعريف الارتباط "الكوكيز" والتي هي عبارة عن ملفات صغيرة تخزّن ضمن القرص الصلب من
      جهاز الكمبيوتر الخاص بك. وعندما تقوم بزيارة صفحات إلكترونية محددة من تطبيق Busy، تقوم ملفات الكوكيز بتعريف المتصفح
      الخاص بك من خلال رقم مميز وعشوائي دون أن تكشف عن أي معلومات شخصية متعلقة بك.
      <br />
      • تساعدنا ملفات الكوكيز على تحسين تجربة استخدامك للتطبيق ومعرفة الأقسام التي تحظى بأكبر نسبة زيارة من تطبيق Busy،
      وتذكر تفضيلات اللغة التي اختارها المستخدم، وتوفير خدماتنا وتحسينها وتطويرها بشكل مستمر.
      <br />
      • تستطيع التحكم في ملفات الكوكيز من إعدادات المتصفح المستخدم في الوصول إلى خدماتنا.
      <br />
      <br />
      <h5>(8) الأطراف الثالثة</h5>
      • قد نقوم بتوفير خدمات أطراف ثالثة سواء كانت متعاقد معنا أو غير متعاقدة معنا، مثل مواقع التواصل الاجتماعي وخدمات
      الدفع الالكتروني وغيرها من خدمات الجهات الأخرى.
      <br />
      • قد يقوم الطرف الثالث بجمع واستخدام بعض البيانات الخاصة بك وتحديد هويتك من خلال معرف الجهاز وعنوان IP وغيرها من
      الوسائل، ويعد الطرف الثالث مسئولا عن استخدام البيانات الخاصة بك.
      <br />
      <br />
      <h5>(9) حقوقك</h5>
      • من حقك الحصول على كافة البيانات التي نحتفظ بها عنك.
      <br />
      • لك الحق في الاعتراض على المعالجة لبعض البيانات الشخصية التي تخصك، ويحق لك تعديل هذه البيانات أو حذفها.
      <br />
      • في بعض الأحيان قد يتعين علينا الاحتفاظ بالبيانات لفترات مطلوبة قانونيًا لحماية حقوقنا أو حقوق مستخدمينا.
      <br />
      <br />
      <h5>(10) التعديلات</h5>
      • يحتفظ تطبيق Busy بحق تعديل سياسة الخصوصية في أي وقت، ويعلمك باستخدام التطبيق بالموافقة على التعديلات فور حدوثها.
      <br />
      <br />
    </section>
  );
};

export default Privacy;
