import styled from "styled-components";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import right from "../../assets/images/right.svg";

const Services = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const Title = styled.h2`
    font-size: 24px;
    line-height: 28px;
    color: #0a1730;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 30px;

    @media (min-width: 768px) {
      font-size: 28px;
      line-height: 32px;
      margin-top: 50px;
      margin-bottom: 40px;
    }

    @media (min-width: 1024px) {
      font-size: 32px;
      line-height: 36px;
      margin-top: 57px;
      margin-bottom: 50px;
    }
  `;

  const Service = styled.div`
    display: flex;
    align-items: start;
    gap: 15px;
    margin: 20px 0;

    @media (min-width: 768px) {
      gap: 20px;
      margin: 23px 0;
    }

    @media (min-width: 1024px) {
      gap: 27px;
    }
    @media (min-width: 1580px) {
      margin: 40px 0;
    }
  `;

  const RightIcon = styled.img`
    position: relative;
    top: 2px;
    width: 20px;
    height: 20px;

    @media (min-width: 768px) {
      width: 24px;
      height: 24px;
    }
  `;

  const ServiceTitle = styled.h3`
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 6px;

    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 28px;
    }

    @media (min-width: 1024px) {
      font-size: 24px;
      line-height: 33.6px;
      margin-bottom: 8px;
    }
  `;

  const ServiceDesc = styled.p`
    font-size: 14px;
    line-height: 20px;
    color: #0a1730;
    font-weight: 400;
    opacity: 0.6;

    @media (min-width: 768px) {
      font-size: 15px;
      line-height: 22px;
    }

    @media (min-width: 1024px) {
      font-size: 17px;
      line-height: 23.8px;
    }
  `;

  return (
    <div>
      <Title data-aos="fade-up">مقدمي الخدمات</Title>
      <Service data-aos="fade-left">
        <RightIcon src={right} alt="" />
        <div>
          <ServiceTitle>توفير العمل لدي الأفراد و الشركات</ServiceTitle>
          <ServiceDesc>مما يساعد على تنويع خبراتهم وزيادة دخلهم.</ServiceDesc>
        </div>
      </Service>
      <Service data-aos="fade-left" data-aos-delay="100">
        <RightIcon src={right} alt="" />
        <div>
          <ServiceTitle>فرص عمل متنوعة</ServiceTitle>
          <ServiceDesc>
            يتيح الوصول إلى مجموعة كبيرة من المشاريع الصغيرة والمهام المتنوعة البسيطة، مما يعكس روح الاقتصاد القائم على
            المهام.
          </ServiceDesc>
        </div>
      </Service>
      <Service data-aos="fade-left" data-aos-delay="200">
        <RightIcon src={right} alt="" />
        <div>
          <ServiceTitle>المرونة في العمل</ServiceTitle>
          <ServiceDesc>
            يسمح باختيار المشاريع وفقاً للجدول الزمني والتفضيلات الشخصية، مما يسهل تحقيق التوازن بين الحياة المهنية
            والشخصية
          </ServiceDesc>
        </div>
      </Service>
      <Service data-aos="fade-left" data-aos-delay="300">
        <RightIcon src={right} alt="" />
        <div>
          <ServiceTitle>معاملات مالية آمنة</ServiceTitle>
          <ServiceDesc>يوفر آليات دفع موثوقة وعقود إلكترونية تحفظ حقوق المستقلين وتضمن استلام الأجور.</ServiceDesc>
        </div>
      </Service>
      <Service data-aos="fade-left" data-aos-delay="400">
        <RightIcon src={right} alt="" />
        <div>
          <ServiceTitle>تطوير السمعة المهنية</ServiceTitle>
          <ServiceDesc>
            يتيح نظام التقييمات للعملاء تقديم ملاحظاتهم، مما يساعد في بناء سمعة قوية وجذب فرص جديدة.
          </ServiceDesc>
        </div>
      </Service>
    </div>
  );
};

export default Services;
