import AppleStoreIcon from "../../icons/AppleStoreIcon";
import GooglePlayIcon from "../../icons/GooglePlayIcon";
import { SCompDownloadApp } from "./styles/SCompDownloadApp";
import { SCompDownloadAppContainer } from "./styles/SCompDownloadAppContainer";
import { SCompDownloadAppContent } from "./styles/SCompDownloadAppContent";
import { SCompDownloadAppImg } from "./styles/SCompDownloadAppImg";
import { SCompDownloadAppImgCont } from "./styles/SCompDownloadAppImgCont";
import { SCompDownloadAppLinks } from "./styles/SCompDownloadAppLinks";
import { SCompDownloadAppList } from "./styles/SCompDownloadAppList";
import { SCompDownloadAppListItem } from "./styles/SCompDownloadAppListItem";
import { SCompDownloadAppText } from "./styles/SCompDownloadAppText";
import { SCompDownloadAppTitle } from "./styles/SCompDownloadAppTitle";
import { SCompDownloadAppWrapper } from "./styles/SCompDownloadAppWrapper";
import image from "../../assets/images/download-app.png";
import apple from "../../assets/images/apple.svg";
import google from "../../assets/images/google.svg";
import circle from "../../assets/images/tick-circle.svg";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import styled from "styled-components";
import { SHeroLines } from "../Hero/styles/SHeroLines";

const CompDownloadApp = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  const ImageStyle = styled.div`
    display: block;
    width: 490px;
    object-fit: contain;
    position relative;
    @media (max-width: 767px) {
      display: none;
    }
  `;
  const SCompDownloadAppTitle2 = styled.h2`
    font-weight: 700;
    font-size: 46px;
    line-height: 1.3;
    color: #202020;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      font-size: 36px;
      font-weight: 500;
    }
  `;
  return (
    <div style={{ position: "relative" }}>
      <SCompDownloadAppContainer>
        <SHeroLines style={{ top: "-110px" }}>
          <svg width="136" height="495" viewBox="0 0 136 495" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M423.523 282.658L92.9024 315.145" stroke="#0A1730" strokeWidth="4" strokeLinecap="round" />
            <path d="M370.777 335.925L81.8303 363.745" stroke="#0A1730" strokeWidth="4" strokeLinecap="round" />
            <path d="M353.617 242.588L109.12 266.613" stroke="#0A1730" strokeWidth="4" strokeLinecap="round" />
            <path d="M465.319 183.528L58.4019 223.512" stroke="#0A1730" strokeWidth="4" strokeLinecap="round" />
          </svg>
        </SHeroLines>
        <SCompDownloadAppWrapper data-aos="fade-left" style={{ alignItems: "start" }}>
          <ImageStyle>
            <img src={image} alt="حمل التطبيق الأن !" data-aos="fade-right" />
          </ImageStyle>
          <SCompDownloadAppContent>
            <SCompDownloadAppTitle2>حمل تطبيق بيزي للبحث عن خدمة الأن !</SCompDownloadAppTitle2>
            <SCompDownloadAppText>
              أول تطبيق سعودي يتبنى نموذج تقديم الخدمات عند الطلب بين أصحاب المهارات الراغبين بالعمل وطالبي الخدمات من
              أفراد أو شركات ، حيث يتم طلب الخدمة وتنفيذها من قبل مقدم الخدمة في وقت محدد وإنهاء العمل وتقييم مقدم
              الخدمة بعد نهاية العمل .
            </SCompDownloadAppText>
            <SCompDownloadAppList>
              <SCompDownloadAppListItem>
                <img src={circle} alt="circle" style={{ marginLeft: "10px" }} />
                توفير العمل لدي الأفراد و الشركات
              </SCompDownloadAppListItem>
              <SCompDownloadAppListItem>
                <img src={circle} alt="circle" style={{ marginLeft: "10px" }} />
                توفير الخدمات لمقدمين الخدمة
              </SCompDownloadAppListItem>
              <SCompDownloadAppListItem>
                <img src={circle} alt="circle" style={{ marginLeft: "10px" }} />
                سرعة الإستجابة و أفضل خدمة
              </SCompDownloadAppListItem>
              <SCompDownloadAppListItem>
                <img src={circle} alt="circle" style={{ marginLeft: "10px" }} />
                توفير الخدمات لمقدمين الخدمة
              </SCompDownloadAppListItem>
            </SCompDownloadAppList>
            <SCompDownloadAppLinks>
              <a target="_blank" href="https://apps.apple.com/sa/app/busy/id6444770742">
                <img src={apple} alt="apple" data-aos="fade-left" />
              </a>
              <a target="_blank" href="https://play.google.com/store/apps/details?id=com.details.busy">
                <img src={google} alt="google" data-aos="fade-left" />
              </a>
            </SCompDownloadAppLinks>
          </SCompDownloadAppContent>
        </SCompDownloadAppWrapper>
      </SCompDownloadAppContainer>
    </div>
  );
};

export default CompDownloadApp;
