import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "../../../constants/mediaquerys";

const Small = css`
  margin-left: -32px;
  line-height: 0;
  margin-bottom: -80px;
  
  img {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

const Medium = css`

`;

const Large = css`
  position: absolute;
  top: 13%;
  left: 4%;
  width: 45%;
  max-height: 100%;
  margin-bottom: 0;
`;

const XLarge = css``;

const XXLarge = css``;

export const SHeroImage = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
