import styled from "styled-components";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import click from "../../assets/images/click.svg";

const Header = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const Container = styled.section`
    width: 100%;
    height: 270px;
    background: #e5f97f;
    display: flex;
    align-items: center;
    padding: 0 20px;

    @media (min-width: 768px) {
      padding: 0 60px;
    }

    @media (min-width: 1024px) {
      padding: 0 100px;
    }

    @media (min-width: 1280px) {
      padding: 0 134px;
    }
  `;

  const Head = styled.h1`
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    color: #202020;
    margin-bottom: 12px;

    @media (min-width: 768px) {
      font-size: 32px;
      line-height: 40px;
    }

    @media (min-width: 1024px) {
      font-size: 36px;
      line-height: 44px;
    }

    @media (min-width: 1280px) {
      font-size: 40px;
      line-height: 48px;
    }
  `;

  const Para = styled.p`
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      font-size: 15px;
      line-height: 22.5px;
    }
  `;

  const Button = styled.a`
    width: 140px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 7px;
    background: #0a1730;
    font-size: 15px;
    font-weight: 700;
    line-height: 22px;
    color: #e5f97f;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      background: #2a3a5f;
      transform: translateY(-2px);
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    }

    @media (min-width: 768px) {
      width: 160px;
      height: 48px;
      font-size: 16px;
      line-height: 24px;
    }

    @media (min-width: 1024px) {
      width: 180px;
      height: 52px;
    }

    & span {
      margin-bottom: 2px;
    }
  `;

  return (
    <Container>
      <div>
        <Head data-aos="fade-up">التسجيل كمقدم خدمة</Head>
        <Para data-aos="fade-up" data-aos-delay="100">
          التسجيل كمقدم خدمة يمكنك من البحث عن فرص العمل وتقديم العروض
        </Para>
        <Button data-aos="zoom-in" data-aos-delay="200" href="/registration/form">
          <img src={click} alt="" />
          <span>سجل الأن</span>
        </Button>
      </div>
    </Container>
  );
};

export default Header;
